.pill-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.radio-button {
  height: 24px;
  width: 24px;
  accent-color: var(--black);
}

.accordion {
  div {
    padding-top: 0;
    padding-bottom: 0;
    border: none;
  }
  button {
    border-bottom: 1px solid var(--grey200);
  }
}

.accordion-item {
  button {
    padding: 0 1.5rem;
    border-top: none !important;
  }
}

.root {
  position: relative;
  height: 48px;
  overflow: hidden;
}

.root,
.linkbar-expanded,
.linkbar-loading {
  border-bottom: 1px solid var(--grey200);
  box-sizing: border-box;
}

.linkbar,
.linkbar-loading,
.linkbar-expanded,
.linkbar-variant,
.linkbar-minimal {
  display: flex;
  padding: 8px;
}

.linkbar-expanded {
  flex-direction: row;
  box-sizing: border-box;
}

.linkbar-expanded-inner {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  row-gap: 8px;
}

.linkbar-variant {
  position: absolute;
  left: 0;
  top: 0;
}

.linkbar-minimal {
  overflow: hidden;
}

.clickable {
  border-radius: 16px;
  background: inherit;
  cursor: pointer;
  color: black;
}

.clickable:hover {
  background: var(--grey200);
}

.clickable:active {
  background: #ccc;
}

.clickable:visited {
  color: black;
}

.breadcrumb {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 700;
  gap: 4px;
  padding: 8px;
  height: 32px;
  box-sizing: border-box;
}

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.current,
.current-no-truncate,
.current-truncate {
  padding: 8px;
  font-size: 12px;
  font-weight: 400;
  color: var(--grey718);
  white-space: nowrap;
  height: 32px;
  box-sizing: border-box;
}

.current-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

.current-no-truncate {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

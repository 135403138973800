.profile-header {
  display: flex;
  padding: 24px;
  padding-right: 40px;
}
.profile-header-info {
  flex-grow: 1;
  text-align: start;
  padding-left: 24px;
}
.text-gap {
  padding-bottom: 8px;
}

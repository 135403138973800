.full-color-section {
  --colour-text-and-icon-2: rgb(255, 255, 255);

  background-color: rgb(255, 255, 255);
  margin: 0 auto 10px auto;
  max-width: 300px;
  padding: 24px 16px;
}

.full-color-section.green {
  background-color: rgb(10, 138, 0);
  color: rgb(255, 255, 255);
}

.full-color-section.blue {
  background-color: rgb(0, 88, 163);
  color: rgb(255, 255, 255);
}

.full-color-section > * {
  margin-bottom: 16px;
}

.full-color-section > *:last-child {
  margin-bottom: 0;
}

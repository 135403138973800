.simple-main-header {
  background-color: rgb(0, 62, 114);
  display: none;
  flex-shrink: 0;
  height: 72px;
  padding: 0 0 0 16px;
}

.simple-main-header.display-all {
  display: flex;
}

.simple-main-header-text {
  color: #ffdb00;
  align-content: center;
}

@media screen and (min-width: 900px) {
  .simple-main-header {
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .simple-main-header {
    padding: 0 48px 0 64px;
  }
}

.main-container {
  background: #0058a3;
  height: 100vh;
}

.outer-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.logo-text-container {
  padding-top: 21vh;
}

.login-privacy-container {
  padding-bottom: 21vh;
}

.text-container {
  color: #ffcd3f;
  text-align: center;
  padding-top: 16px;
  font-weight: 700;
  font-size: 52px;
}

.text-container-small {
  color: #ffcd3f;
  text-align: center;
  padding-top: 16px;
  font-weight: 400;
  font-size: 16px;
}

.privacy-container {
  padding-top: 16px;
  color: #ffffff;
  text-align: center;
}

.filters-panel {
  div:first-child {
    padding: 0;
    overflow: hidden;
  }
}

.select-all {
  background-color: white;
  border-bottom: solid 1px var(--grey200);
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--grey718);
  font-weight: 700;
  font-size: 14px;
  padding: 0px 16px;
}

.selected-counter {
  color: var(--grey718);
  font-weight: 400;
  font-size: 14px;
}

.header {
  border-bottom: 1px solid var(--grey200);
}

.footer {
  border-top: 1px solid var(--grey200);
}

.loading {
  display: flex;
  justify-content: center;
  height: 100px;
}

.filters-modal {
  div {
    padding: 0;
    overflow-x: hidden;
    scrollbar-width: none;
  }
}

.filters-modal-header-border {
  border-bottom: 8px solid var(--grey100);
}

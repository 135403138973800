.naming-guidelines-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  width: 300px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  max-width: 540px;
  margin: 0 auto;
}

.convention-number {
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  color: rgba(0, 0, 0);
  display: flex;
  font-weight: 700;
  justify-content: center;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 40px;
}

.convention-table td:first-child {
  font-weight: 700;
}

.convention-table td:nth-child(2) {
  padding: 8px 14px;
}

.example-label {
  background-color: rgb(245, 245, 245);
  color: rgb(0, 0, 0);
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
  text-align: center;
}

.example-label + .example-label {
  margin-left: 8px;
}

.example-label span {
  color: rgb(118, 118, 118);
}

.example-label.small {
  min-width: 32px;
  padding: 0 3px;
}

.example-label.large {
  display: inline-block;
  font-size: 36px;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 12px;
}

.italic {
  font-style: italic;
}

.centered {
  margin-bottom: 0;
  text-align: center;
  & > * {
    margin-bottom: 16px;
  }
}

.introduction {
  margin-bottom: 16px;
  padding: 16px 0;

  p + h2 {
    margin-top: 32px;
  }
}

.teams-list-view-item {
  padding: 0px 16px;
}

.teams-list-view-item > .list-view-item__inset {
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
}

.teams-list-view-item > .list-view-item__action {
  min-height: 0;
}

.teams-list-view-item-group {
  display: flex;
}

.main-content {
  background-color: var(--white);
  flex: 1 0 0;
}

.main-content-scroll {
  overflow: auto;  /* TODO: Fix horizontol scroll (hide overflow x) */
}

.main-content-no-scroll {
  scrollbar-width: none;
  height:auto;
  overflow: auto;
}

.grey-background {
  background-color: var(--grey100);
}

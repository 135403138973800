* {
  margin: 0;
  padding: 0;
  border: 0;
  box-shadow: none;
  font-family: NotoSansIKEA;
  -webkit-box-shadow: none;
  -webkit-tap-highlight-color: transparent;
}
*:active,
*:focus,
textarea:focus,
input:focus {
  -webkit-box-shadow: none;
}

html {
  font-size: 16px;
}

button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  border: none;
}
* {
  -webkit-tap-highlight-color: transparent;
}

.applied-filters {
  display: none;
  @media screen and (min-width: 1000px) {
    display: flex;
    gap: 8px;
    padding: 0px 0px 24px 0px;
    flex-direction: column;
  }
}

.title {
  font-size: 12px;
  color: var(--grey718);
}

.pill-wrapper {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

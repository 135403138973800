.swipeable-task-card {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: var(--white);
  user-select: none;
}

.swipe-left {
  transform: translateX(-100px);
  transition: transform 0.3s;
}

.swipe-right {
  transform: translateX(100px);
  transition: transform 0.3s;
}

.swipe-big-right {
  transform: translateX(200px);
  transition: transform 0.3s;
}

.swipe-none {
  transform: translateX(0);
  transition: transform 0.3s;
}

.button-more,
.button-pickup-task {
  background: var(--grey900);
  position: absolute;
  top: 0;
  width: 100px;
  height: 100%;
  border: none;
  color: var(--white);
  font-size: 12px;
  cursor: pointer;
  transition: transform 0.3s;
  padding: 5px;
  box-sizing: border-box;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.button-more {
  right: -1px;
  transform: translateX(100%);
}

.button-pickup-task {
  left: -1px;
  transform: translateX(-100%);
}

.button-hidden {
  transform: translateX(0);
}

.button-more-visible {
  transform: translateX(100%);
}

.button-pickup-task-visible {
  transform: translateX(-100%);
}

.complete-buttons {
  position: absolute;
  top: 0;
  left: -1px;
  height: 100%;
  transform: translateX(-35%);
  transition: transform 0.3s;
}

.complete-buttons-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.button-complete,
.button-complete-with-changes {
  width: 100px;
  height: 100%;
  border: none;
  color: var(--white);
  font-size: 12px;
  cursor: pointer;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.button-complete {
  background: var(--green-darker);
}

.button-complete-with-changes {
  background: var(--green);
}

.complete-buttons-hidden {
  transform: translateX(-100%);
}

.complete-buttons-visible {
  transform: translateX(0);
}

.button-text {
  width: 90%;
}

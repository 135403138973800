.header {
  border-bottom: 1px solid var(--grey200);
}

.search-wrapper {
  padding: 16px 24px;
  border-bottom: 1px solid var(--grey200);
}

.footer {
  border-top: 1px solid var(--grey200);
}

.confirm-button {
  padding: 12px 24px;
}

.modal-body {
  padding-top: 8px !important;
}

.form-field {
  margin-bottom: 0px;
}

.skeleton-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 8px;
}

.select-label {
  font-size: 14px;
  margin-bottom: 0.125rem;
}

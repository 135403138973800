.vertical-center {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: space-between;
}

.home-outer-container {
  display: flex;
  flex-direction: column;
}

.home-container {
  background: var(--grey100);
  padding-left: 16px;
  padding-top: 24px;
}

@media (min-width: 768px) {
  .HomeContainer {
    justify-content: unset;
  }
}

@media (min-width: 1440px) {
  .HomeContainer {
    padding-left: 64px;
    padding-top: 24px;
    padding-right: 64px;
    justify-content: unset;
  }
}

.quick-actions-container {
  padding-top: 16px;
}

.header-wrapper {
  height: 35vh;
  background-color: var(--grey100);
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.header-container-text {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;
}

.current-store {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.store-name {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

.contact-store-super-user-message {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

@media screen and (min-width: 900px) {
  .logo-wrapper {
    display: none;
  }
  .main-header {
    display: flex;
  }
}

.logo-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 25px;
}

.header-logo {
  width: 58px;
  height: 35px;
}

@media screen and (min-width: 1440px) {
  .main-header,
  .header-wrapper {
    padding: 0 48px 0 64px;
  }
  .logo-wrapper {
    display: none;
  }
  .header-wrapper {
    height: 20vh;
  }
}

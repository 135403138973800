li.accordion-item-title > div > button {
  border: 0;
  padding: 0 24px;
  height: 48px;
  min-height: 48px;
  border-top: 1px solid var(--grey100) !important;
}
li.accordion-item-title > div > div > div > div > ul > li:first-child {
  border-top: 0;
}
li.accordion-item-title > div > div > div > div > ul > li {
  border-top: 1px solid var(--grey100);
}
li.accordion-item-title > div > div {
  border-bottom: 8px solid var(--grey100);
}

.full-screen-popup {
  background: rgb(255, 255, 255);
  bottom: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;

  &.no-padding .content {
    padding: 0;
  }
}

.content {
  padding: 20px;
}

.date-filter-tabs {

  /* button {
    margin-left: 1.5rem;
  } */
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.date-content-divider {
 position: fixed;
  z-index: 1;
  background-color: #19aab7;
  overflow-x: hidden;
  padding-top: 20px;
}
.insights-search-input {
  /* position: fixed; */
padding : 20px 24px 15px
}
.container {
  position: relative;
}
.insights-iframe {
  width: 100%;
  height: 100%;
  overflow: hidden;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.team-form-field :global(.input-field__wrapper) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.form-container {
  width: 100%;
  background-color: var(--white);
  display: flex;
  margin-top: 16px;
}

.form-body {
  padding: 0 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
}

.no-teams-container {
  padding-bottom: 32px;
}

.super-users-container {
  padding-bottom: 32px;
}

.continue-button {
  display: none;
}

.action-bar-continue-button {
  display: block;
}

@media screen and (min-width: 1440px) {
  .form-container {
    padding: 0 48px 0 64px;
  }
  .form-body {
    padding: 0px;
  }
  .continue-button {
    padding-bottom: 32px;
    display: block;
  }
  .action-bar-continue-button {
    display: none;
  }
}

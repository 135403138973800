div.tasks-prompt > div > div {
  padding: 0 !important;
}

div.tasks-prompt > div > div > h2 {
  font-size: 16px;
  margin: 16px 1.5rem 8px 1.5rem;
}

.sub-title {
  font-size: 14px;
  margin: 0 1.5rem 16px 1.5rem;
}

.task-options {
  border-top: 8px solid var(--grey150);
  span:first-child {
    color: var(--new);
  }
}

.footer {
  margin: 12px 24px;
}

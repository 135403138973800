.tab-container {
  display: none;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-left: 3rem;

  @media (min-width: 900px) {
    display: flex;
  }
}

.tab-wrapper {
  flex-direction: column;
  position: relative;
}

.tab,
.tab-active {
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.tab {
  color: var(--grey300);
}

.tab-active {
  color: var(--white);
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 50px;
}

.tab-active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  border-radius: 1.5px;
  background-color: var(--white);
  bottom: 0;
}

.task-list-view .border-none-indicator-dot > .status__dot {
  border-style: none;
}

.task-list-view .list-view-item__description,
.task-list-view .list-view-item__title--emphasised {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 65vw;
  @media screen and (min-width: 768px) {
    width: 80vw;
  }
  @media screen and (min-width: 1440px) {
    width: 35vw;
  }
}

.filters-row-container {
  overflow: auto hidden;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  scrollbar-width: none;
}

.filters-row-overlay-arrow-left {
  position: absolute;
  z-index: 1;
  left: 26px;
}

.filters-row-overlay-arrow-right {
  position: absolute;
  z-index: 1;
  right: 26px;
}

.filters-container {
  display: flex;
  padding: 0px !important;
  margin: 16px 0px 16px 8px;
  position: relative;
}

.container {
  background: var(--white);
  width: 100%;
  border-top: 1px solid var(--grey200);
}

.container-overflow {
  overflow-y: auto;
}

.container-spacer {
  min-height: calc(100% - 70px);
}

.keep-task,
.suggestions {
  border-top: 10px solid var(--grey150) !important;
  border-bottom: 10px solid var(--grey150) !important;
  label {
    box-sizing: border-box;
  }
  span {
    box-sizing: border-box;
  }
}

.app-bar {
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(223, 223, 223);
  box-sizing: border-box;
  display: flex;
  height: 56px;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 101;
}

.actions-left,
.actions-right {
  min-width: 50px;
  width: auto;
  flex-grow: 0;
}

.center {
  flex: 1;
}

.actions-left,
.actions-right {
  display: flex;
  align-items: center;
}

.actions-right {
  justify-content: flex-end;
}

.actions-right {
  text-align: right;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* The 1px min-width allows the flex child container to
     shrink even when the inner inline content is wider
     than the container */
  min-width: 1px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.app-bar:not(.with-subtitle) .title {
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.subtitle {
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
}

.icon-wrapper {
  cursor: pointer;
  margin: 0 0 0 12px;
  font-size: 14px;
  color: var(--grey900);

  @media screen and (max-width: 400px) {
    margin: 0 0 0 6px;
  }
}

.icon-wrapper:first-child {
  margin-left: 0;
}

.icon-wrapper:last-child {
  margin-right: 0;
}

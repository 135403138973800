.applied-filter-text {
  font-size: 12px;
  color: var(--grey718);
}

.applied-filter-display-none {
  display: none;
}

.applied-filter-container {
  margin: 12px 0 12px 0;
}

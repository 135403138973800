.add-custom-location {
  background-color: rgb(245, 245, 245);
}

.section {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px -1px 0px 0px #dfdfdf inset;
  margin-bottom: 8px;
  padding: 0 24px 24px 24px;
}

.food-articles-information {
  margin-bottom: 8px;
  font-weight: 200;
  padding: 8px 24px 16px 24px;
  color: #484848;
}

.query-preview {
  color: rgb(17, 17, 17);
  font-size: 14px;
  font-weight: 700;
  padding: 16px 24px;
}

.information-section {
  padding: 24px;
}

.footer {
  background-color: rgb(255, 255, 255);
  bottom: 0;
  left: 0;
  padding: 24px;
  position: fixed;
  right: 0;
}

.filters-panel {
  div {
    padding: 0;
    overflow-x: hidden;
  }
}

.location {
  div {
    padding: 0 !important;
  }
}

.separator {
  border-top: 24px solid var(--grey100);
}

.warning-message {
  white-space: normal;
  margin: 24px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

.location-tabs {
  button {
    margin-left: 1.5rem;
  }
}

.search-wrapper {
  margin: 1rem 1.5rem 0.5rem 1.5rem;
}

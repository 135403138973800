.section-header {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  height: 40px;
}

.section-header-text {
  padding-left: 16px;
}

.hidden-overflow-x {
  overflow-x: hidden;
}

.profile-list-item > span {
  padding-block-start: 0 !important;
  padding-block-end: 0 !important;
}

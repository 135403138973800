.load-more {
  background: var(--white);
  padding: 24px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content-wrapper {
  width: 200px;
}

.counts {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--grey700);
  font-size: 12px;
}

.bar {
  display: flex;
  margin-top: 12px;
  margin-bottom: 24px;
  width: 100%;
  height: 2px;
  background: var(--grey200);
}

.progress {
  background: var(--grey800);
}

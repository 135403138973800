.team {
  height: 4rem;
  display: flex;
}
.member {
  display: flex;
  img {
    border-radius: 50%;
  }
}

.container {
  background: var(--new);
  width: auto;
  padding: 12px 24px;
  color: var(--white);
  margin: 24px 0px 16px 0px;
  margin-left: -24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  user-select: none;
  cursor: pointer;
}

.top-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.light-icon {
  width: 16px;
  height: 16px;
}

.discover-now {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.focus-tile {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

.description {
  font-size: 14px;
  font-weight: 400;
}

.arrow-icon-wrapper {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.arrow-icon {
  width: 24px;
  height: 24px;
}

.sheet-title {
  font-size: 24px;
  font-weight: 700;
  color: var(--grey900);
  margin-top: 8px;
  margin-bottom: 12px;
}

.set-up {
  font-size: 14px;
  margin-bottom: 32px;
}

.step-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 24px;
  color: var(--grey900);
}

.step-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 21.99px;
  color: var(--grey718);
}

.divider {
  width: 100%;
  height: 1px;
  background: var(--grey200);
  margin: 32px 0px;
}

.footer {
  border-top: 1px solid var(--grey100);
}

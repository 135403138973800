.bottom-navigation {
  align-items: center;
  border-top: 1px solid var(--grey200);
  display: flex;
  height: 67px;
  justify-content: center;
  background: var(--white);
  @media (min-width: 1200px) {
    display: none;
  }
}

.navigation-item {
  align-items: center;
  color: rgb(118, 118, 118);
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 12px;
  height: 100%;
  justify-content: center;
  position: relative;
}

.navigation-item.active-item {
  color: rgb(17, 17, 17);
}

.top-border {
  background-color: transparent;
  border-radius: 1.5px;
  height: 3px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color 0.1s;
}

.active-item .top-border {
  background: rgb(0, 88, 163);
}

.task-list {
  background-color: var(--grey100);
}

.tasks {
  scroll-margin: 350px;
}

.counter {
  background-color: white;
  border-bottom: solid 1px var(--grey200);
  font-size: 14px;
  font-weight: 700;
  height: 48px;
  line-height: 48px;
  margin-bottom: 10px;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  color: var(--grey718);
}

.select-all {
  background-color: white;
  border-bottom: solid 1px var(--grey200);
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--grey718);
  font-weight: 700;
  font-size: 14px;
}

.load-more-button {
  background: var(--white);
  padding: 24px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

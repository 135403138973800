.big-image {
  width: 106px;
  height: 106px;
  object-fit: cover;
  border-radius: 50%;
}
.small-image {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
}

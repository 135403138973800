.hamburger-menu {
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: none;
  margin-right: 5px;
  @media screen and (min-width: 900px) {
    display: block;
  }

  @media screen and (min-width: 1440px) {
    position: absolute;
    left: 1px;
  }
}

.dropdown-pill {
  display: block;
}
.dropdown-backdrop {
  background-color: transparent;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}
.dropdown-list {
  margin-top: 10px;
  z-index: 101;
}

.overview-container {
  margin: auto;
  width: 100%;
  height: stretch;
  @media screen and (min-width: 1200px) {
    padding: 20px;
    width: 90%;
  }
}

.title {
  font-size: 16px;
  font-weight: 700;
  margin-right: auto;
  padding-left: 20px;
  padding-top: 20px;
  @media screen and (min-width: 1200px) {
    padding: 0px;
    font-size: 24px;
  }
}

.overview-wrapper {
  display: flex;
  justify-content: center;
  margin: auto;
  gap: 8px;
  width: 100%;
  height: fit-content;
  @media screen and (min-width: 1200px) {
    height: 65%;
  }
}

.multi-select-button {
  position: absolute;
  bottom: 86px;
  left: 16px;
  z-index: 1;
  @media screen and (min-width: 1200px) {
    display: none;
  }
}

.action-bar {
  background-color: rgb(255, 255, 255);
  border-top: 1px solid rgb(223, 223, 223);
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  opacity: 1;
  padding: 20px;
  position: sticky;
  transition: bottom 0.23s, opacity 0.3s;
  z-index: 101;

  &.hidden {
    bottom: -100px;
    opacity: 0;
    pointer-events: none;
  }
}

.full-height-content {
  position: fixed;
  width: 100%;
}
